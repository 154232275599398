.max-w-500px {
  max-width: 500px !important;
}

.max-h-208px {
  max-height: 208px !important;
}

.h-30px {
  height: 30px !important;
}

.h-full {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-46px {
  height: 46px;
}